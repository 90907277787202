import React from 'react';

export default ({ target, children, ...props }) => (
  <a
    {...props}
    href={target}
    onClick={e => {
      e.preventDefault();
      document.querySelector(target).scrollIntoView({
        behavior: 'smooth',
      });
    }}>
    {children}
  </a>
);
