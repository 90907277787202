import React from 'react';
import { Link } from 'gatsby';

import SmoothAnchor from 'src/components/SmoothAnchor';
import { GatsbyImage } from 'gatsby-plugin-image';

const MainHeader = ({ issues }) => {
  const scrollRef = React.useRef(null);

  return (
    <header className="header-main">
      <h1 className="header-title">The Leading Internet Trends Magazine</h1>

      {issues.slice(0, 3).map((issue, id) => {
        const isFrontIssue = id === 0;
        if (!isFrontIssue)
          return <GatsbyImage alt={''} image={issue} className={'issue issue-back'} />
        return (
          <Link key={id} to={'/releases'} className={'issue issue-front'}>
            <span className={'visually-hidden'}>View All Releases</span>
            <GatsbyImage alt={''} image={issue} className={'issue issue-front'} />
          </Link>
        );
      })}
      <span className="header-cta">
        <h4>a Know Your Meme publication</h4>
        Want print copies?{' '}
        <Link
          ref={scrollRef}
          to="https://memeinsider.memberful.com/join"
          onClick={e => e.target.blur()}
          className="accent">
          Click here!
        </Link>
      </span>
      <SmoothAnchor id="anchor" target="#anchor" className="header-scroll">
        Scroll Down To See More
      </SmoothAnchor>
    </header>
  );
};

export default MainHeader;
