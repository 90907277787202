import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from 'src/layout/Layout';
import SEO from 'src/components/global/seo';
import MainHeader from 'src/components/MainHeader';
import Divider from 'src/components/Divider';
import AnalyticsScript from 'src/components/Analytics';

import Stroke from 'src/assets/svgs/stroke.svg';

function processReleaseCovers(releaseCovers) {
  return releaseCovers
    .map(({ image, parent }) => ({ image, ...parent }))
    .filter(({ slug, context }) => slug !== '' && context === 'releases')
    .reduce((collect, { slug, image }) => ({ ...collect, [slug]: image }), {});
}

function processReleaseMetadata(releaseMetadata) {
  return releaseMetadata
    .map(({ frontmatter: { published }, parent }) => ({ published, ...parent }))
    .filter(({ slug, context }) => slug !== '' && context === 'releases')
    .reduce((collect, { slug, published }) => ({ ...collect, [slug]: published }), {});
}

const Home = ({ data }) => {
  const {
    bannerFile,
    releases: { nodes: allReleases },
  } = data;
  const latestIssues = allReleases.sort(
    ({ published: a }, { published: b }) => new Date(b) - new Date(a)
  );

  return (
    <Layout className="home">
      <SEO title="Home" />
      <MainHeader issues={latestIssues.map(issue => issue.cover.childImageSharp.gatsbyImageData)} />
      <Link to="https://memeinsider.memberful.com/join" className="home-promo-banner" id="ml">
        <span className="ml-title">Want to be an insider?</span>
        <p className="ml-description">
          Get print copies by becoming a <span>Premium Subscriber</span>
        </p>
        <span className="hpb-cta">Find Out How</span>
      </Link>
      <section className="latest-articles">
        <h2 className="section-title coming-soon">Latest Articles</h2>
        <div className="stroke-text">
          <span>Coming Soon</span>
          <Stroke className="stroke" />
        </div>
        <Link to="/" className="section-cta coming-soon" tabIndex="-1" aria-hidden>
          All Articles
        </Link>
      </section>
      <Divider />
      <section className="latest-issues">
        <h2 className="section-title">Latest Releases</h2>
        <div className="issue-display">
          {latestIssues.slice(0, 3).map(release => (
            <Link to={`/releases/${release.slug}`}>
              <GatsbyImage alt={''} image={release.cover.childImageSharp.gatsbyImageData} />
            </Link>
          ))}
        </div>
        <Link to="/releases" className="section-cta">
          All Releases
        </Link>
      </section>
      <Divider />
      <section className="featured-video">
        <h2 className="section-title">Featured Video</h2>
        <iframe
          title="The Most Popular YouTuber Who's Never Uploaded [ft. Down the Rabbit Hole]"
          src="https://www.youtube.com/embed/_M3IZ8x2KRk"
          frameBorder="0"
          allowFullScreen
        />
        <div className="featured-video-banner">
          <Img fluid={bannerFile.childImageSharp.fluid} alt="" />
        </div>
      </section>
      <AnalyticsScript />
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery {
    releases: allRelease {
      nodes {
        cover {
          childImageSharp {
            gatsbyImageData
          }
        }
        slug
        published
      }
    }
    bannerFile: file(relativePath: { eq: "banner.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Home;
